import React, { useState, useEffect } from "react";

export default function Hubspote({ handleHubspot, isHubSpot }) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const closeHubSpot = () => {
    handleHubspot(!isHubSpot);
    document.body.style.overflow = "auto";
  };

  const dialogWidth = isMobile ? "100%" : "65vw";
  const dialogHeight = isMobile ? "90%" : "auto";
  const iframeHeight =  "calc(100vh - 120px)" 

  return (
    <>
      <div
        className="fixed z-30 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div
            className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle lg:w-6/12 2xl:w-3/5 p-6`}
            style={{ width: dialogWidth, height: dialogHeight }}
          >
            <div
              id="video_close_btn"
              className="absolute opacity-100 cursor-pointer z-30 -top-2 right-2 text-shark-500 p-2"
              aria-hidden="true"
              onClick={() => closeHubSpot()}
              style={{
                fontSize: "45px",
                lineHeight: "30px",
                transform: "rotate(45deg)",
              }}
            >
              <span>+</span>
            </div>
            <div className="mt-2">
              <iframe
                title="meeting"
                src="https://meetings.hubspot.com/sales-guy?embed=true"
                frameBorder="0"
                style={{ width: "100%", height: iframeHeight }}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
